body {
    background-color: #f5f6f8;
}

html,
body {
    font-family: 'Roboto', sans-serif;
}

p {
    color: #6c757d;
}

.content-cont {
    margin: 0 auto;
    margin-top: 30px;

}

.content-cont-wrapper {
    display: flex;
}

.content-cont-wrapper .content-sidebar-wrapper {
    width: 18%;
    padding-left: 3rem;
    padding-right: 1rem;
}

.content-cont-wrapper .content-right-wrapper {
    width: 82%;
    padding-left: 1rem;
    padding-right: 3rem;
}

.top-bar .main-nav.navbar ul li a {
    padding: 0 25px;
    margin: 0 auto;
    height: 65px;
    background-color: #496399;
}

.top-bar h2 {
    font: normal normal normal 22px/26px Roboto;
    letter-spacing: 0;
    color: #496399;
    margin-bottom: 0;
}

header {
    background: #FEFEFE 0 0 no-repeat padding-box;
    box-shadow: 0 1px 2px #00000029;
    opacity: 1;
    height: 56px;
}

.inner-body-cont {
    background: white;
    min-height: 100vh;
    padding: 25px 25px;
    box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);
}

.page-head {
    font: normal normal normal 22px/28px Roboto;
    letter-spacing: 0;
    /*border-bottom: 1px solid #c1c1c1;*/
    padding-bottom: 10px;
    font-size: 1.1rem;
    margin: 0;
    color: #1b1e21;
    margin-bottom: 10px;
    line-height: 100%;

}

.sb-title {
    font-size: 13px;
    font-weight: 500;
    color: #6e768e;
    letter-spacing: 0.7px;
    margin-bottom: 30px;
}

.main-nav.navbar ul li a {
    font: normal normal normal 18px/22px Roboto;
    letter-spacing: 0;
    color: #6e768e;
    margin-bottom: 0;
    font-size: 15px;
    font-weight: 00;
    display: flex;
    align-items: center;
}



.main-nav.navbar ul li a:hover {
    text-decoration: none;
}

.main-nav .nav-item {
    display: flex;
    flex-wrap: wrap;
    padding: 5px 0;
    width: 100%;
    flex-direction: column;
    margin-bottom: 15px;
}

.main-nav .nav-item:last-child {
    margin-bottom: 0;
}

.divider {
    border-bottom: solid 1px #bcbcbc;
    margin: 10px 0 10px;
}

.main-nav .navbar-nav {
    width: 100%;
}

.main-nav .nav-item img {
    display: inline-block;
    margin-right: 8px;
    width: 21px;
}

.main-nav .navbar-nav a {
    max-width: 280px;
}

.main-nav.navbar-nav {
    margin-top: 30px;
    width: 100%;
}


.main-nav.navbar-nav a.active {
    background: #EBF0FC;
    border-top-right-radius: 25px;
    border-bottom-right-radius: 25px;
}


.main-nav.navbar {
    position: relative;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    padding: 30px 25px;
    width: 100%;
    background-color: #ffffff;
    box-shadow: 0 0.75rem 6rem rgb(56 65 74 / 3%);
}


.commn-btn {
    font: normal normal normal 18px/22px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    display: inline-block;
    padding: 7px 22px;
    background-color: #496399;
    border-radius: 5px;
    font-size: 15px;
}

.commn-btn:hover,
.commn-btn:focus,
.commn-btn:active {
    text-decoration: none;
    color: #ffffff;
}

.table-cont {
    margin-top: 30px;
}


.btn-bloat-right {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

/*connection table starts here*/
.commn-table-cont {
    margin-top: 15px;
}

.commn-table-cont table tbody tr th:first-child {
    background-color: #f4f5f7;
}

.commn-table-cont .table thead tr {
    border-top: solid 0 transparent;
}

.commn-table-cont table {
    border: 1px solid #cfcfcf3b;
    font-size: 15px;
    border-top: none;
    border-left: 0;
    border-right: none;
    margin-bottom: 30px;
}

.commn-table-cont table thead {
    background-color: #f8f9fa66;
}

.commn-table-cont table .first-row {
    width: 16%;
}

.commn-table-cont table .second-row {
    width: 22%;
}


.commn-table-cont table .third-row {
    width: 20%;
}

.commn-table-cont table .fourth-row {
    width: 20%;
}

.commn-table-cont table .fourth-row a {
    color: #2d5cbb;
}

.commn-table-cont table p {
    color: #6c757d;
    margin: 0;
}

.commn-table-cont table .fourth-row a.view-link {
    color: #2d5cbb;
    display: inline-block;
    margin-right: 25px;
    margin-top: 0;
}

.commn-table-cont table .fourth-row a.delete-link img,
.commn-table-cont table .fourth-row span.delete-link img,
.commn-table-cont table .fourth-row a.view-link img {
    width: 17px;
}


.commn-table-cont table .fourth-row a.delete-link,
.commn-table-cont table .fourth-row span.delete-link {
    cursor: pointer;
}

.commn-table-cont table .third-row p {
    display: inline-block;
    /* background-color: #446397; */
    padding: 3px 15px;
    border-radius: 5px;
    /* color: #ffffff; */
    margin-bottom: 0;
    font-size: 14px;
}

.commn-table-cont .table thead tr th {
    vertical-align: bottom;
    border-bottom: 2px solid #dee2e6;
    color: #6c757d;
}

.commn-table-cont .table tbody tr th,
.commn-table-cont .table tbody tr td {
    color: #6c757d;
    font-weight: 400;
    vertical-align: middle;
    padding: 8px 10px;
}

.commn-table-cont tbody th {
    font-weight: 500;
}

.commn-table-cont thead th {
    font-weight: 700;
}

.commn-table-cont.flow-table .first-row {
    width: 10%;
}

.commn-table-cont.flow-table .second-row {
    width: 30%;
}

/*connection table ends here*/


/*flow form starts*/

.cont-form-all .submit-cont {
    justify-content: flex-start;
    margin-top: 0;
}

.cont-form-all .submit-cont.add-submit-cont input[type="submit"] {
    border: none;
    font: normal normal normal 18px/22px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    display: inline-block;
    padding: 10px 50px;
    background-color: #496399;
    border-radius: 5px;
    font-size: 16px;
    transition: 0.5s ease-in-out;
}


.cont-form-all .all-form-fl-w-ip {
    border: none;
    background: #F8F9FA;
    padding: 12px 15px;
    color: #707070;
}

.cont-form-all .form-control {
    height: unset;
}


.cont-form-all .label-input-cont {
    display: Flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    color: #6c757d;
    font-size: 14px;
}

.label-input-cont.check-label-input-cont {
    display: flex;
    flex-direction: column;
    justify-content: initial;
    align-items: initial;
}

.cont-form-all .label-input-cont.check-label-input-cont input[type="checkbox"] {
    width: auto;
    position: inherit;
    left: 0;
}

.cont-form-all .label-input-cont.check-label-input-cont .checkbox-schedule-span {
    position: absolute;
    left: 38px;
}

.cont-form-all .label-input-cont p {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 6px;
}

.common-input-field-wrapper label {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 6px;
    color: #6c757d;
}

.cont-form-all .label-input-cont .all-form-fl-w-ip {
    width: 100%;
    border-radius: 0.2rem;
    border: solid 1px #ced4da;
    background-color: transparent;
    margin-bottom: 0;
    padding: 10px 10px;
    font-size: 15px;
}

.cont-form-all .label-input-cont .all-form-fl-w-ip:hover,
.cont-form-all .label-input-cont .all-form-fl-w-ip:focus,
.cont-form-all .label-input-cont .all-form-fl-w-ip:active {
    outline: none;
    box-shadow: none;
}

.cont-form-all .label-input-cont .outer-input-div {
    width: 100%;
}


.cont-form-all .label-input-cont .outer-input-div .error-message {
    padding-left: 0;
    color: red;
    padding-top: 5px;
}

.cont-form-all .label-input-cont {
    margin-bottom: 30px;
}

.cont-form-all .dropdown-response-cont .label-input-cont p {
    width: 21%;
}

.cont-form-all .dropdown-response-cont .label-input-cont .all-form-fl-w-ip {
    width: 71%;
}

.cont-form-all .all-form-fl-w-ip.chk-bx {
    background: none;
}

.cont-form-all .add-conne-type-btn {
    display: flex;
    flex-wrap: Wrap;
    justify-content: flex-end;
    width: 100%;
}

.cont-form-all .add-conne-type-btn button {
    font: normal normal normal 18px/22px Roboto;
    font-family: Roboto;
    letter-spacing: 0;
    color: #FFFFFF;
    display: inline-block;
    padding: 15px 35px;
    background-color: #0055FF;
    border-radius: 5px;
}

.task-type-checkbox input[type="checkbox"],
.task-type-checkbox input[type="radio"] {
    margin-right: 15px;
}

.cont-form-all .label-input-cont input[type="checkbox"] {
    width: 30px;
    position: absolute;
    left: 15%;
}

.all-form-fl-w-ip.title-edit {
    background: white;
    font-weight: bold;
}

.checkbox-attribute {}

.cont-form-all .label-input-cont input[type="checkbox"].checkbox-attribute {
    width: 30px;
    position: static;
    display: inline-block;
}


.form-control.all-form-fl-w-ip.schedule-email-field {
    width: 100%;
}

span.checkbox-schedule-span {
    position: absolute;
    left: 18%;
}

.read-more-text {
    cursor: pointer;
    color: #2d5cbb
}


.all-form-fl-w-ip.remove-attribute-button {
    display: inline-block;
    padding: 5px 28px;
    background-color: #2d5cbb;
    color: white;
    border-radius: 5px;
    width: unset !important;
    cursor: pointer;
}

@media only screen and (max-width:992px) {
    .cont-form-all .label-input-cont p {
        width: 100%
    }

    .cont-form-all .label-input-cont input {
        width: 100%;
    }

    .cont-form-all,
    .inner-body-cont {
        padding: 10px;
    }
}

.loader {
    width: 30px;
    height: 30px;
    border-top: 3px solid rgb(255, 255, 255) !important;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;
    border-bottom: 3px solid rgb(255, 255, 255) !important;
    animation: loader 2s linear infinite;
    z-index: 1000000;
    border-radius: 50px;
}

@keyframes loader {
    0% {
        transform: rotate(0deg);
    }

    50% {
        border-top: 3px solid rgb(2, 173, 136) !important;
        border-bottom: 3px solid rgb(231, 255, 12) !important;
    }

    100% {
        transform: rotate(360deg);
    }
}

.spinner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: linear-gradient(rgba(0, 0, 0, 0.733), rgba(0, 0, 0, 0.473));
    color: #fff;
    opacity: 0.4;
    display: flex;
    justify-content: center;
    align-items: center;
}

/*flow form ends*/

.add-attribute-btn {
    cursor: pointer;
    font: normal normal normal 18px/22px DM Sans;
    letter-spacing: 0;
    color: #ffffff;
    padding: 10px 0;
    background-color: #496399;
    font-family: 'DM Sans', sans-serif;
    border-radius: 5px;
    border: none;
    font-size: 16px;
    width: 180px;
    justify-content: center;
    align-items: center;
    display: flex;
}

.add-attribute-btn.remove {
    background-color: #a30000;
}

.add-attribute-btn:hover {
    color: white;
    text-decoration: none;
}


.cont-form-all .label-input-cont .outer-input-div {}

.cont-form-all .label-input-cont .outer-input-div .all-form-fl-w-ip {
    width: 100%;
    border-radius: 0.2rem;
    border: solid 1px #ced4da;
    background-color: transparent;
    padding: 10px 10px;
    font-size: 15px;
    color: #6c757d;
}

.common-input-field-wrapper input,
.common-input-field-wrapper select {
    width: 100%;
    border-radius: 0.2rem;
    border: solid 1px #ced4da;
    background-color: transparent;
    padding: 10px 10px;
    font-size: 15px;
    height: auto;
}

.common-input-field-wrapper input:hover,
.common-input-field-wrapper input:focus,
.common-input-field-wrapper input:active {
    box-shadow: none;
    outline: none;
    border: solid 1px #ced4da;
}

.common-input-field-wrapper select:hover,
.common-input-field-wrapper select:focus,
.common-input-field-wrapper select:active {
    box-shadow: none;
    outline: none;
    border: solid 1px #ced4da;
}

.common-input-field-wrapper {
    margin-bottom: 30px;
}

.cont-form-all .label-input-cont .outer-input-div .all-form-fl-w-ip::-webkit-input-placeholder {
    color: #b9b9b9;
}

.cont-form-all .label-input-cont .outer-input-div .all-form-fl-w-ip::-ms-input-placeholder {
    color: #b9b9b9;
}

.cont-form-all .label-input-cont .outer-input-div .all-form-fl-w-ip:-ms-input-placeholder {
    color: #b9b9b9;
}

.cont-form-all .label-input-cont .all-form-fl-w-ip::-webkit-input-placeholder {
    color: #b9b9b9;
}

.cont-form-all .label-input-cont .all-form-fl-w-ip::-ms-input-placeholder {
    color: #b9b9b9;
}

.cont-form-all .label-input-cont .all-form-fl-w-ip:-ms-input-placeholder {
    color: #b9b9b9;
}

.logout {
    cursor: pointer;
    font: normal normal normal 18px/22px Roboto;
    letter-spacing: 0;
    color: #6e768e;
    font-size: 15px;
    display: flex;
    align-items: center;
}


.table td,
.table th {
    padding: 0.75rem;
    vertical-align: top;
    border-top: 1px solid #cfcfcf3b;
}

input.register-submit {
    font: normal normal normal 18px/22px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    display: inline-block;
    padding: 12px 20px;
    background-color: #496399;
    border-radius: 5px;
    border: none;
    font-size: 16px;
}

a.edit-link-view {
    border: none;
    font: normal normal normal 18px/22px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    display: inline-block;
    padding: 10px 50px;
    background-color: #496399;
    border-radius: 5px;
    font-size: 16px;
    transition: 0.5s ease-in-out;
}

a.edit-link-view:hover,
a.edit-link-view:focus,
a.edit-link-view:active {
    text-decoration: none;
}

.single-view-table.table td,
.single-view-table.table th {
    border-top: none;
}

#delete-confirmation-modal .modal-content {
    border-radius: 0;
}

#delete-confirmation-modal .modal-content .modal-header .modal-title {
    color: #1b1e21;
    font-size: 1.1rem;
    font-weight: 400;
}

#delete-confirmation-modal .modal-content .modal-body h5 {
    color: #1b1e21;
    font-size: 18px;
    font-weight: 400;
}

.modal-footer button {
    font: normal normal normal 18px/22px Roboto;
    letter-spacing: 0;
    color: #ffffff;
    display: inline-block;
    padding: 7px 22px;
    background-color: #496399;
    border-radius: 5px;
    font-size: 15px;
    border: none;
}

.modal-footer button:hover,
.modal-footer button:focus,
.modal-footer button:active {
    box-shadow: none;
    border: none;
    outline: none;
    color: #ffffff;
}

.modal-footer button.btn-red {
    background-color: #a30000;
}


/* Scroll Bar */

body::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6) !important;
    background-color: #CCCCCC !important;
    border-radius: 8px !important;
}

body::-webkit-scrollbar {
    width: 8px !important;
    background-color: #F5F5F5 !important;
}

body::-webkit-scrollbar-thumb {
    background-color: #FFF !important;
    background-image: -webkit-linear-gradient(90deg,
            rgb(255, 255, 255) 0%,
            rgb(212, 212, 212) 25%,
            transparent 100%,
            rgb(194, 194, 194) 75%,
            transparent);
    border-radius: 10px !important;

}

/* Scroll Bar End  */

/*Monitor View Starts*/

.monitor-table-cont .monitor-table-head {
    margin-bottom: 15px;
    margin-top: 10px;
}

.monitor-log-cont {
    padding: 10px;
    max-height: 80vh;
}

.Toastify__progress-bar {
    background: black;
}

/*Monitor View Ends*/

/* Navigation active */

.nav-item a{
    padding: 0.5rem 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    transition: 0.5 ease-in;
}

.nav-item a.active {
    width: 100%;
    transform-origin: center;
    transition: 0.5s ease-out;
    padding: 0.5rem 1rem;
    border: 1px solid #446397;
    border-left: none;
}

/* Navigation active End*/


.css-1u3bzj6-MuiFormControl-root-MuiTextField-root {
    margin: 0 10px 0 0px !important;
}

@media screen and (max-width: 1500px) {
    .content-cont-wrapper .content-sidebar-wrapper {
        width: 22%;
    }

    .content-cont-wrapper .content-right-wrapper {
        width: 78%;
    }
}

@media screen and (max-width: 1200px) {
    .content-cont-wrapper .content-sidebar-wrapper {
        width: 24%;
    }

    .content-cont-wrapper .content-right-wrapper {
        width: 76%;
    }
}

@media screen and (max-width: 991px) {
    .content-cont-wrapper {
        flex-direction: column;
    }

    .content-cont-wrapper .content-sidebar-wrapper {
        width: 100%;
        padding: 0 1rem;
    }

    .content-cont-wrapper .content-right-wrapper {
        width: 100%;
        padding: 0 1rem;
    }
}